/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 15/10/2023
 * @description The `ProposalBudgetsTable` component displays a table of budgets related to a proposal. Users can view details such as company information, name, modality, annual payment, paid type, and first bill. Actions include seeing, editing, creating an offer, and removing budgets. Additionally, users can select budgets for comparison and access a dialog for comparing coverages. The component supports pagination, filtering, sorting, lazy loading, and selection of multiple budgets. A toolbar is provided for comparing coverages, and a dialog is used for displaying the coverages comparison table. The `getCompaniesByBudgets` function fetches company data for each budget in the array asynchronously.
 */
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import {deleteTableRowUtil} from "../../utils/deleteTableRow.util";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent/ToastContent";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";
import {Dialog} from "primereact/dialog";
import CoveragesComparativeTable from "../CoveragesComparativeTable/CoveragesComparativeTable";
import {Toolbar} from "primereact/toolbar";

export default function ProposalBudgetsTable({proposal}) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [proposalBudgets, setProposalBudgets] = useState([])
    const [selectedBudgets, setSelectedBudgets] = useState([])
    const [isShowingComparativeModal, setIsShowingComparativeModal] = useState(false)
    const [proposalsInOfferCount, setProposalsInOfferCount] = useState(0)

    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const {data: proposalBudgets} = await API(
            "policy/budget/list/" + proposal.id + createFilterParamsUtil(event) + "&extraFields=budgetCoverages,budgetCoverages.coverage"
        );
        const proposalBudgetsWithCompanies = await getCompaniesByBudgets(proposalBudgets)

        updateProposals(proposalBudgetsWithCompanies)
    };

    const updateProposals = (newProposals) => {
        setProposalBudgets(newProposals);
        setProposalsInOfferCount(newProposals.reduce((sum, proposal) => proposal.isInOffer ? sum + 1 : sum, 0))
    }

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);


    const InsuranceCustomerServicesActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: <span className="me-4 material-symbols-outlined">visibility</span>,
                command: () => navigate("./budgets/" + rowData.id)

            }, {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate("./budgets/" + rowData.id + "/edit")
            }, {
                label: t("See PDF"),
                icon: <span className="me-4 material-symbols-outlined">description</span>,
                command: () => window.open(process.env.REACT_APP_IMAGE_URL + rowData.fileLocation)
            }, {
                label: t("Create offer") + " (Max 3)",
                icon: <span className="me-4 material-symbols-outlined">savings</span>,
                command: async () => {
                    try {

                        await API.put(
                            "policy/budget/update/" + rowData.id, {isInOffer: true})
                        toast.info(<ToastContent title={t("Offer created")}
                        />)
                        setProposalsInOfferCount(proposalsInOfferCount + 1)
                    } catch (e) {
                        console.error(e)
                    }
                },
                disabled: rowData.isInOffer || proposalsInOfferCount >= 3

            }, {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => deleteTableRowUtil(rowData.id, "policy/budget/delete/" + rowData.id, proposalBudgets, updateProposals),
            }
        ];
        return <ActionsOverlayMenu items={items}/>
    }

    const synchronize = async () => {
        console.log("Sincronizando con Avant...");

        const {data: result} = await API.get("data/project/insurances/" + proposal.projectId);
        var newIssuanceIds = result.offers.filter(
            o => o.appUrls
            ?.find(appUrl => appUrl.rel == "NewIssuance"))
            ?.filter(o => !proposalBudgets?.find(b => b.codeoscopicQuoteId == o.mainQuote?.id))
            ?.map(o => o.mainQuote.id);
        if (newIssuanceIds.length === 0) {
            console.log("Finalizada la sincronizando con Avant...");
            return;
        }

        const quotes = result.mainQuotes.filter(q => newIssuanceIds.includes(q.id));
        const budgets = []
        
        for (let i = 0 ; i < quotes.length; i++) {
            var quote = quotes[i]; 
            const {data: companies} = await API.get("company/company/all?filter[erpId]=" + quote.product.vendor.id);
            var b = {
                proposalId: Number(proposal.id),
                companyId: companies[0].id,
                "uploadFiles": {
                    "fileLocation":"data:application/pdf;base64,cXFxcQ==" // MOCK de PDF en blanco
                },
                name: quote.product.vendor.name,
                modalityName: quote.product.modality.name,
                frequency: quote.paymentFrequency.id.toUpperCase(),
                firstBill: quote.downPayment,
                otherBills: 0,
                codeoscopicQuoteId: quote.id
            }
            
            var {data: b} = await API.post("policy/budget/create", b);
            budgets.push(b);
        }

        const {data: {data: coveragesByType}} = await API("policy/policy-type/coverage/list/" + proposal.policyTypeId);

        var coverages = coveragesByType.map(c => {return {coverageId: c.id, description: c.name}});

        if (coverages.length > 0) {
            for (let i = 0 ; i < budgets.length; i++)
                await API.put("policy/budget/coverage/update/" + budgets[i].id, coverages);
        }

        console.log("Finalizada la sincronizando con Avant...");

        getLazyData(lazyTable);
    };

    const tableHeader = () => <div className={"d-flex justify-content-end"}>
        {proposalBudgets.length >= 10 && <p className={"me-4"}>{t("Max number of budgets is")} 10</p>}
        <Link className={proposalBudgets.length >= 10 ? "u-disabled" : ""}
              to={"/proposals/" + proposal.id + "/budgets/new"}>
            <Button label={t("Create budget")}></Button>
        </Link>
        <Link className={ proposal.projectId ? "ms-6" : "ms-6 u-disabled"} onClick={synchronize}>
            <Button label={t("Synchronize with Avant")} ></Button>
        </Link>
    </div>

    return <div>
        <DataTable emptyMessage={t("No results found")}
                   header={(proposal && proposal.status === "OFFER_PENDING") ? tableHeader : null}
                   value={proposalBudgets}
                   filterDisplay="row"
                   dataKey="id"
                   paginator
                   totalRecords={lazyTable.totalRecords}
                   rows={lazyTable.rows}
                   onPage={onLazy}
                   onFilter={onLazy}
                   onSort={onLazy}
                   lazy
                   first={lazyTable.first}
                   sortOrder={lazyTable.sortOrder}
                   selectionMode={'checkbox'}
                   selection={selectedBudgets}
                   onSelectionChange={(e) => setSelectedBudgets(e.value)}

        >
            <Column selectionMode="multiple"></Column>
            <Column filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Company")}
                    body={({company}) => company.logo ?
                        <img width="100" src={process.env.REACT_APP_IMAGE_URL + company.logo}
                             alt={t("Company")}/> : company.name}/>
            <Column field="name" filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Name")}/>
            <Column field="modalityName" filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Modality")}/>
            <Column filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Annual payment")} body={(rowData) => rowData.firstBill + rowData.otherBills + "€"}
            />
            <Column filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Paid type")} body={({frequency}) => t(frequency)}
            />
            <Column filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("First bill")} body={(rowData) => (rowData.firstBill ? rowData.firstBill : 0) + "€"}
            />


            {(proposal && proposal.status === "OFFER_PENDING") &&
                <Column header={t("Actions")} body={InsuranceCustomerServicesActions}/>}
        </DataTable>

        <Toolbar className="mb-4" start={() => <Button
            className={selectedBudgets.length === 0 || selectedBudgets.length >= 10 ? "u-disabled" : ""}
            label={t("Compare coverages")} onClick={() => setIsShowingComparativeModal(true)}></Button>}></Toolbar>


        <Dialog header={t("Coverages")} visible={isShowingComparativeModal}
                onHide={() => setIsShowingComparativeModal(false)}>
            <CoveragesComparativeTable budgets={selectedBudgets} policyTypeId={proposal.policyTypeId}/>
        </Dialog>
    </div>
}

export const getCompaniesByBudgets = async (array) => {
    let companies = {};
    let companyData;
    for (const item of array) {
        if (!companies[item.companyId]) {
            const {data: company} = await API(
                "company/company/view/" + item.companyId
            );
            companyData = company;
        } else {
            companyData = companies[item.companyId]
        }
        item.company = companyData;
    }

    return array;
}
